import React from 'react'
import { string, object } from 'prop-types'

import { linkResolver } from '../../util/linkResolver'
import { ArrowInCircle } from '../Icons'
import * as Styled from './styles/CtaLink.styles'

const FunctionTriggerLink = ({ triggerFunction, additionalStyles, text }) => (
  <Styled.CtaContainer
    as="a"
    onClick={triggerFunction}
    $additionalStyles={additionalStyles}
    target="#"
  >
    <Styled.TextLink>{text}</Styled.TextLink>
    <ArrowInCircle />
  </Styled.CtaContainer>
)

const ExternalLink = ({ link, additionalStyles, text }) => (
  <Styled.CtaContainer
    as="a"
    href={link}
    $additionalStyles={additionalStyles}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Styled.TextLink>{text}</Styled.TextLink>
    <ArrowInCircle />
  </Styled.CtaContainer>
)

const InternalLink = ({ link, additionalStyles, text }) => {
  const isBlankTarget = link.target === '_blank'
  return isBlankTarget ? (
    <Styled.CtaContainer
      as="a"
      href={linkResolver(link)}
      $additionalStyles={additionalStyles}
      target={link.target}
      rel="noopener noreferrer"
    >
      <Styled.TextLink>{text}</Styled.TextLink>
      <ArrowInCircle />
    </Styled.CtaContainer>
  ) : (
    <Styled.CtaContainer
      to={linkResolver(link)}
      $additionalStyles={additionalStyles}
    >
      <Styled.TextLink>{text}</Styled.TextLink>
      <ArrowInCircle />
    </Styled.CtaContainer>
  )
}

const CtaLink = ({ text, link, additionalStyles = null }) => {
  if (!link) {
    return null
  }
  // if (!link.link_type) {
  //   return null
  // }
  if (link.link_type && link.link_type === 'Function') {
    // Edge case currently only used in Admin page
    return (
      <FunctionTriggerLink
        triggerFunction={link.function}
        additionalStyles={additionalStyles}
        text={text}
      />
    )
  }
  if (
    (link.link_type && link.link_type === 'Document') ||
    (link.type && link.type === 'advance_article') // Handle exception where Advance Article doesn't provide link_type
  ) {
    if (!link.uid) {
      return null
    } // only link to valid page types
    return (
      <InternalLink
        link={link}
        additionalStyles={additionalStyles}
        text={text}
      />
    )
  }
  if (
    link.link_type &&
    (link.link_type === 'Web' ||
      link.link_type === 'File' ||
      link.link_type === 'Media')
  ) {
    return (
      <ExternalLink
        link={link.url}
        additionalStyles={additionalStyles}
        text={text}
      />
    )
  }
  return null
}

CtaLink.propTypes = {
  text: string,
  link: object,
  additionalStyles: string,
}

CtaLink.defaultProps = {
  additionalStyles: '',
}

export default CtaLink
